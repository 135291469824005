import React, { useState, useEffect } from 'react';  // Add useEffect import
import { BrowserRouter as Router, Route, Switch, Link, useLocation } from 'react-router-dom';
import SuggestToolButton from './components/SuggestToolButton';
import BlogList from './pages/BlogList';
import BlogPost from './pages/BlogPost';

const Footer = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <footer className="fixed bottom-0 left-0 right-0 bg-gray-800 py-2 sm:py-4">
      <div className="max-w-4xl mx-auto px-4 flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0">
        <div className="flex items-center space-x-4">
          <p className="text-gray-400 text-xs sm:text-sm">© 2024 - The A.I Hire</p>
          {isHomePage ? (
            <Link to="/blog" className="text-gray-400 text-xs sm:text-sm hover:text-white transition-colors duration-200">
              Blog
            </Link>
          ) : (
            <Link to="/" className="text-gray-400 text-xs sm:text-sm hover:text-white transition-colors duration-200">
              Home
            </Link>
          )}
        </div>
        <div className="flex items-center space-x-2 sm:space-x-4">
          <SuggestToolButton />
          <a
            href="https://toolsforindiehackers.com"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center px-3 py-1 sm:px-4 sm:py-2 bg-blue-600 text-white text-xs sm:text-sm rounded-full hover:bg-blue-700 transition-colors duration-200"
          >
            Find builder tools
          </a>
          <a
            href="https://twitter.com/AntoineLevy27"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center px-3 py-1 sm:px-4 sm:py-2 bg-black text-white text-xs sm:text-sm rounded-full hover:bg-gray-700 transition-colors duration-200"
          >
            <svg className="w-4 h-4 sm:w-5 sm:h-5 mr-1 sm:mr-2" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
            </svg>
            Follow @AntoineLevy27
          </a>
        </div>
      </div>
    </footer>
  );
};

const productStages = [
  {
    name: 'Marketing',
    tools: [
      { name: 'Meet Cody', description: 'Proficient AI-powered chatbot designed to offer users valuable marketing support and insights.', url: 'https://meetcody.ai/' },
      { name: 'Jasper', description: 'transform your creative capability, better connect with your customers, and secure your competitive advantage.', url: 'https://www.jasper.ai/' },
      { name: 'AskCory', description: 'Generate strategic tactics, action plans and content assets. Effortlessly integrates actionable insights and benchmarks, in just 10min!', url: 'https://tertle.io/' },
      { name: 'Owliwriter A.I', description: 'Social media content generator', url: 'https://www.hootsuite.com/platform/owly-writer-ai' },
    ]
  },
  {
    name: 'Development',
    tools: [
      { name: 'Github Copilot', description: 'AI pair programmer', url: 'https://github.com/features/copilot' },
      { name: 'Cursor', description: 'AI code editor', url: 'https://www.cursor.com/' },
      { name: 'Cody', description: 'AI code assistant', url: 'https://sourcegraph.com/cody' },
    ]
  },
  {
    name: 'Design',
    tools: [
      { name: 'UX Pilot', description: 'AI UX assistant', url: 'https://uxpilot.ai/' },
      { name: 'Uizard', description: 'AI design assistant', url: 'https://uizard.io/design-assistant/' },
    ]
  }
];

const operationsStages = [
  {
    name: 'Hiring',
    tools: [
      { name: 'Persona Studio', description: 'AI assistant for first round interviews', url: 'https://personastudios.ai/' },
    ]
  },
  {
    name: 'Legal',
    tools: [
      { name: 'Robin AI', description: 'Legal AI for contracts', url: 'https://www.robinai.com/' },
      { name: 'Truelaw AI', description: 'Legal AI assistant powered by your data', url: 'https://www.truelaw.ai/' },
      { name: 'Luminance', description: 'Legal AI copilot', url: 'https://www.luminance.com/' },
    ]
  },
  {
    name: 'Customer Service',
    tools: [
      { name: 'Forethought AI', description: 'Customer service AI agent', url: 'https://forethought.ai/' },
      { name: 'Zendesk', description: 'AI poweredcustomer support platform', url: 'https://www.zendesk.co.uk/' },
      { name: 'Freshdesk', description: 'AI poweredcustomer support platform', url: 'https://freshdesk.com/' },
      { name: 'Intercom', description: 'AI poweredcustomer support platform', url: 'https://www.intercom.com/' },
    ]
  }
];

const TabNavigation = ({ openStage, toggleStage, stages }) => (
  <nav className="tab-navigation">
    <ul className="flex justify-center list-none p-0 m-0 bg-opacity-80 bg-gray-800 rounded-2xl overflow-hidden max-w-3xl mx-auto">
      {stages.map((stage, index) => (
        <li key={index} className="flex-1">
          <button
            className={`w-full py-3 px-4 text-center text-sm sm:text-base transition-all duration-300 ease-in-out ${
              openStage === index ? 'bg-gray-700 text-white' : 'text-gray-400 hover:bg-gray-700 hover:text-white'
            }`}
            onClick={() => toggleStage(index)}
          >
            {stage.name}
          </button>
        </li>
      ))}
    </ul>
  </nav>
);

const ExpandedContent = ({ stage }) => {
  return (
    <div className="mt-2 max-w-3xl mx-auto">
      <ul className="space-y-4">
        {stage.tools.map((tool, toolIndex) => (
          <li key={toolIndex} className="bg-gray-800 bg-opacity-50 p-4 rounded-lg">
            <a href={tool.url} className="text-gray-100 hover:text-blue-400 hover:underline text-lg font-bold">{tool.name}</a>
            <p className="text-gray-100 mt-2">{tool.description}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

const UserTypeSwitch = ({ userType, setUserType }) => (
  <div className="flex justify-center mb-6">
    <div className="bg-gray-800 rounded-full p-1 flex items-center h-8">
      <button
        className={`px-3 py-1 text-xs rounded-full transition-all duration-300 ${
          userType === 'product' ? 'bg-gray-700 text-white' : 'text-gray-400'
        }`}
        onClick={() => setUserType('product')}
      >
        Product A.I
      </button>
      <button
        className={`px-3 py-1 text-xs rounded-full transition-all duration-300 ${
          userType === 'operations' ? 'bg-gray-700 text-white' : 'text-gray-400'
        }`}
        onClick={() => setUserType('operations')}
      >
        Operations A.I
      </button>
    </div>
  </div>
);

const IndieHackerToolsDirectory = () => {
  const [openStage, setOpenStage] = useState(0);  // Changed from null to 0
  const [userType, setUserType] = useState('product');

  useEffect(() => {
    setOpenStage(0);  // Reset to first category whenever userType changes
  }, [userType]);

  const toggleStage = (index) => {
    setOpenStage(openStage === index ? null : index);
  };

  const stages = userType === 'product' ? productStages : operationsStages;

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-700 to-gray-800 p-4 sm:p-8">
      <div className="max-w-4xl mx-auto">
        <div className="mb-16 sm:mb-16">
          <UserTypeSwitch userType={userType} setUserType={setUserType} />
        </div>
        <h1 className="text-3xl sm:text-4xl font-bold mb-4 sm:mb-8 text-center text-gray-100">The A.I Hire</h1>
        <p className="text-center mb-8 sm:mb-12 text-xl sm:text-2xl text-gray-300">
          Find and compare the best verticalised AI tools for your business.
        </p>
        <div className="mb-6 sm:mb-12">
          <TabNavigation openStage={openStage} toggleStage={toggleStage} stages={stages} />
        </div>
        {openStage !== null && (
          <div className="mb-12 sm:mb-24">
            <ExpandedContent stage={stages[openStage]} />
          </div>
        )}
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={IndieHackerToolsDirectory} />
        <Route exact path="/blog" component={BlogList} />
        <Route path="/blog/:id" component={BlogPost} />
      </Switch>
      <Footer />
    </Router>
  );
};

export default App;
