import React from 'react';
import { Link } from 'react-router-dom';

const blogPosts = [
  {
    id: 'future-of-startups',
    title: 'The Future of Startups: Verticalised Agents as First Hires',
    excerpt: 'Speculating on the impact of A.I systems, specifically verticalised agents, as tech companies first hires.',
  },
  // Add more blog posts here as needed
];

const BlogList = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-700 to-gray-800 p-4 sm:p-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl sm:text-4xl font-bold mb-8 text-center text-gray-100">Blog Articles</h1>
        <div className="space-y-6">
          {blogPosts.map((post) => (
            <div key={post.id} className="bg-gray-800 bg-opacity-30 p-4 rounded-lg backdrop-blur-sm">
              <Link to={`/blog/${post.id}`} className="text-gray-100 hover:text-blue-300 hover:underline text-lg font-bold">
                {post.title}
              </Link>
              <p className="text-gray-300 mt-2">{post.excerpt}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogList;
